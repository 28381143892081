<template>
    <div>
        <BaseDialog :show="toggle" @update:show="$emit('update:toggle', $event)" @close="close"
            title="Upload app CSV with list of users access">
            <template slot="content">
                <div class="text-body-2 grid-layout-column grid-gap-lg">
                    <ApplicationAutocomplete v-if="applications" style="max-width: 50%;" :selectedApplications.sync="selectedApplications" :edit="false"
                        :applications="applications"/>
                    <span>
                        Easily sync your users by exporting a CSV from your app's admin portal and uploading it here. No
                        export option? Use
                        <a href="https://storage.googleapis.com/yeshid-app-production-assets/yeshid-import-users.csv"
                            download>
                            this template
                        </a>
                        instead.
                    </span>
                    <div v-show="!file" class="text-body-2 grid-layout-column grid-gap-lg pb-14">
                        <FormCheckbox v-model="bypassLLM" text="Import without using the LLM"></FormCheckbox>
                        <FileInput ref="file" fileFormat="text/csv" edit
                            fileFormatError="Invalid file format. Please upload a csv file only."
                            @file="f => bypassLLM? showFile(f): uploadLLM(f)" :maxSize="1024 * 1024">
                            <template #activator="{ on }">
                                <v-btn v-on="on" elevation="0" color="primary">Upload CSV</v-btn>
                            </template>
                        </FileInput>
                    </div>
                    <div v-if="thinking">
                        <v-btn @click="showOutput = !showOutput" outlined text>LLM output</v-btn>
                        <div v-show="showOutput">
                            <v-textarea readonly :value="thinking"/>
                        </div>
                    </div>
                    <v-data-table v-if="(headers.length && rows.length) || csvLoading" :loading="csvLoading" :headers="headers" :items="rows"></v-data-table>
                    <div v-if="file && !bypassLLM">
                        Does this look correct? If not you can
                        <a @click="uploadLLM(file, true)">retry the LLM mapping</a>.
                    </div>
                </div>
            </template>
            <template slot="error" v-if="error">
                {{ error }}
            </template>
            <template slot="actions">
                <v-btn @click="close" outlined text>Cancel</v-btn>
                <v-btn v-if="file" @click="bypassLLM? uploadStrictCSV(): uploadMappingCSV()" :loading="csvLoading" text class="primary">Load CSV</v-btn>
            </template>
        </BaseDialog>
    </div>
</template>

<script>
import Papa from 'papaparse';
import BaseDialog from '@/components/dialog/BaseDialog.vue'
import ApplicationAutocomplete from '@/components/ApplicationAutocomplete.vue';
import FileInput from '@/components/FileInput.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';

export default {
    components: {
        BaseDialog,
        ApplicationAutocomplete,
        FileInput,
        FormCheckbox,
    },
    props: {
        toggle: {
            type: Boolean,
            default: false,
            required: true,
        },
        application: {
            type: Object,
        },
    },
    data() {
        return {
            selectedApplications: this.application,
            applications: [],
            error: "",
            file: "",
            headers: [],
            rows: [],
            thinking: "",
            bypassLLM: false,
            csvLoading: false,
            showOutput: false,
        }
    },
    watch: {
        application(val) {
            this.selectedApplications = val
        }
    },
    mounted() {
        this.getApplications()
    },
    computed: {
        selectedApplicationId() {
            return this.selectedApplications && this.selectedApplications.id
        }
    },
    methods: {
        close() {
            this.clearValues()
            this.clearFile()
            this.$emit('update:toggle', false)
        },
        clearValues() {
            this.error = ""
            this.file = ""
            this.headers = []
            this.rows = []
            this.thinking = ""
        },
        clearFile() {
            this.$refs.file.clearFile()
        },
        getApplications() {
            this.loading = true
            this.$http.get('/api/v1/applications').then(resp => {
                this.applications = resp.data.applications
            }).catch(err => {
                this.error = `There was an error getting applications: ${err?.response?.data?.message || "unknown"}`
            }).finally(() => {
                this.loading = false
            })
        },
        start() {
            this.error = ""
            this.csvLoading = true
            this.headers = []
            this.rows = []
        },
        showFile(file) {
            this.start()
            this.file = file
            console.log(this.file)

            let that = this
            Papa.parse(this.file, {
                complete: function(results) {
                    if (results.errors.length) {
                        console.log('csv parse errors', results.errors)
                        that.file = ""
                        that.csvLoading = false
                        that.error = `Unable to parse CSV, encountered ${results.errors.length} errors.`
                        that.clearFile()
                        return
                    }

                    let data = results.data
                    if (data.length < 2) {
                        console.log('csv requires a header and data')
                        that.file = ""
                        that.clearFile()
                        return
                    }

                    let headers = []
                    for (const h in data[0]) {
                        headers.push({
                            text: data[0][h],
                            value: data[0][h],
                        })
                    }
                    that.headers = headers

                    let remainingRows = data.slice(1)
                    let rows = []
                    for (const i in remainingRows) {
                        let r = remainingRows[i]
                        if (r.length != headers.length) {
                            continue
                        }
                        let row = {}
                        for (let j = 0; j < r.length; j++) {
                            row[headers[j].value] = r[j]
                        }
                        rows.push(row)
                    }
                    that.rows = rows
                    that.csvLoading = false
                },
                error: function(error) {
                    console.log("got error parsing csv:", error)
                    that.file = ""
                    that.csvLoading = false
                    that.clearFile()
                }
            })
        },
        uploadLLM(file, forceLLMRemap=false) {
            this.start()
            this.file = file
            let formData = new FormData()
            formData.append('application_users', file)
            formData.append('json_body', JSON.stringify({
                "forceLLMRemap": forceLLMRemap
            }))
            this.$http.post(`/api/v1/application/${this.selectedApplicationId}/users/csv/parse`, formData).then((response) => {
                let rows = response.data.sampleRows
                this.thinking = response.data.thinking
                let headers = []
                for (const h in response.data.headers) {
                    headers.push({
                        text: response.data.headers[h],
                        value: response.data.headers[h],
                    })
                }
                this.headers = headers
                this.rows = rows
            }).catch(err => {
                this.file = ""
                this.error = `There was an error while uploading your file: ${err?.response?.data?.message || "unknown"}`
                this.clearFile()
            }).finally(() => {
                this.csvLoading = false
            })
        },
        uploadStrictCSV() {
            let formData = new FormData()
            formData.append('application_users', this.file)
            this.$http.post(`/api/v1/application/${this.selectedApplicationId}/users/import`, formData).then(() => {
                if (this.$route.name != "access-applications-accounts" || this.$route.params.appId !== this.selectedApplicationId) {
                    this.$router.push(`/access/applications/${this.selectedApplicationId}/accounts`)
                }
                this.$emit('completed')
                this.close()
            }).catch(err => {
                this.error = `There was an error while uploading your file: ${err?.response?.data?.message || "unknown"}`
            }).finally(() => {
                this.uploading = false
            })
        },
        uploadMappingCSV() {
            let formData = new FormData()
            formData.append('application_users', this.file)
            this.$http.post(`/api/v1/application/${this.selectedApplicationId}/users/import/mapping`, formData).then(() => {
                if (this.$route.name != "access-applications-accounts" || this.$route.params.appId !== this.selectedApplicationId) {
                    this.$router.push(`/access/applications/${this.selectedApplicationId}/accounts`)
                }
                this.$emit('completed')
                this.close()
            }).catch(err => {
                this.error = `There was an error while uploading your file: ${err?.response?.data?.message || "unknown"}`
            }).finally(() => {
                this.uploading = false
            })
        }
    },
}
</script>