<template>
    <v-data-table :items="users" :headers="headers" :search="search">
        <template #[`item.name`]="{ item }"><UserCell :item="item"/></template>
        <template #[`item.idpStatus`]="{ item }"><UserEnrollmentCell :item="item" /></template>
        <template #[`item.firstSeen`]="{ item }"><DateFormat :date-string="item.firstSeen" :date="true" /></template>
    </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import UserCell from '../User/UserCell.vue';
import UserEnrollmentCell from '@/components/UserEnrollmentCell.vue';
import DateFormat from "@/components/DateFormat.vue";

export default {
    props: {
        users: {
            type: Array,
            required: true
        },
        search: {
            type: String,
            required: false,
            default: ""
        }
    },
    computed: {
        ...mapGetters({
            gwsSamlConnected: "gwsSamlConnected",
            checkFeatureFlag: "checkFeatureFlag",
        })
    },
    mounted() {
        // don't show the enrollment state if the org isn't using the yeshid idp
        if (!this.gwsSamlConnected) {
            this.headers = this.headers.filter(e => e.value != 'idpStatus')
        }
    },
    data() {
        return {
            loading: true,
            headers: [
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "First seen",
                    value: "firstSeen",
                },
            ]
        };
    },
    components: { UserCell, UserEnrollmentCell, DateFormat }
}

</script>

