<template>
    <v-combobox
        class="mr-0 mt-0"
        style="margin-right: 0 !important;"
        :value="getFilterPills()"
        readonly
        multiple
        height="42px"
        label=""
        hide-details
        dense>
        <template v-slot:prepend-inner>
            <v-icon size="24">mdi-filter</v-icon>
        </template>
        <template v-slot:selection="data">
            <v-menu v-if="data.item.type === 'addFilter'"
                :key="`filter-chip-${data.index}`"
                offset-y>
                <template v-slot:activator="{ on }">
                    <v-chip class="ma-1" color="white" text-color="primary" v-on="on">{{ data.item.text }}</v-chip>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in filterComponentsNotSelected()"
                        @click="addFilterToView(item)"
                        :key="index"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <component v-else :is="data.item"
                @remove="() => { remove(data.item); removeFilterFromView(data.item); $emit('applied') }"
                @applied="applied"
                :initialFilters="data.item.initialFilters"
                @initialApplied="initialApplied"
            />
        </template>
        <template v-slot:append>
            <v-chip v-show="filters.length" color="white" text-color="primary" @click="clear" size="small">Clear all</v-chip>
        </template>
    </v-combobox>
</template>

<script>

export default {
    props: {
        filters: {
            type: Array,
            default: Array,
        },
        filterComponents: {
            type: Array,
            default: Array,
        },
    },
    data() {
        return {
            filtersToDisplay: [],
            initialFilters: [],
            initialFiltersApplied: false,
        }
    },
    computed: {
        internalFilters: {
            get() {
                return this.filters;
            },
            set(value) {
                this.$emit("update:filters", value);
            }
        },
    },
    mounted() {
        // TODO: will need to consider how filters interact with existing api calls happening
        const urlFilters = this.$route.query.filters || ""

        const filterComponentsToDisplay = []
        const initialFiltersForFilterTypes = {}
        if (urlFilters) {
            const conds = urlFilters.split(new RegExp(" AND | OR "))
            for (const c of conds) {

                // op can be '<', '>', '!=', '=', ':'
                const op = c.match(new RegExp("!=|<=|>=|<|>|:|="))[0]
                let parts = c.split(op)

                const type = (parts[0] || "").trim()
                if (!type) {
                    continue
                }

                const value = (parts[1] || "").trim()
                if (!value) {
                    continue
                }

                const item = this.$api.filters.buildFilterItem("", value, "", op)

                const initialFilter = initialFiltersForFilterTypes[type]
                if (initialFilter) {
                    initialFilter.push(item)
                } else {
                    initialFiltersForFilterTypes[type] = [item]
                }
            }
        } else if (!this.initialFiltersApplied) {
            // pre-applied filters passed in through props
            for (const filter of this.filters) {
                initialFiltersForFilterTypes[filter.type] = filter.selected
            }
        }

        for (const type of Object.keys(initialFiltersForFilterTypes)) {
            const filterComponent = this.findFilterByType(type)
            if (filterComponent) {
                filterComponent.initialFilters = filterComponent.initialFilters || []
                filterComponent.initialFilters = initialFiltersForFilterTypes[type]
                filterComponentsToDisplay.push(filterComponent)
            }
        }

        this.filtersToDisplay.push(...filterComponentsToDisplay)

    },
    methods: {
        filterComponentsNotSelected() {
            return this.filterComponents.filter(f => !this.filtersToDisplay.includes(f))
        },
        getFilterPills() {
            return this.filtersToDisplay.concat([{
                text: "Add filter",
                type: "addFilter",
            }]);
        },
        findFilterByType(type) {
            return this.filterComponents.find(f => f.type === type);
        },
        remove(item) {
            this.internalFilters = this.internalFilters.filter(f => f.type !== item.type)
        },
        add(item) {
            const withoutItem = this.internalFilters.filter(f => f.type !== item.type)
            this.internalFilters = [...withoutItem, item]
        },
        addFilterToView(item) {
            if (this.filtersToDisplay.every(f => f.type !== item.type)) {
                this.filtersToDisplay.push(item);
            }
        },
        removeFilterFromView(item) {
            this.filtersToDisplay = this.filtersToDisplay.filter(f => f.type !== item.type)
        },

        clear() {
            this.internalFilters = []
            this.filtersToDisplay = []
            this.$emit("applied")
        },
        applied(filter) {
            this.add(filter)
            this.$emit("applied")
        },
        initialApplied(filter) {
            this.initialFilters.push(filter)

            // when all the expected initialFilters have been applied we can then apply them to the parent state
            if (this.initialFilters.length === this.filtersToDisplay.length) {
                this.internalFilters = [...this.initialFilters]
                this.$emit("applied")
                this.initialFiltersApplied = true

                // clear out initial values so they don't get applied again
                for (const filter of this.filtersToDisplay) {
                    filter.initialFilters = []
                }
            }
        }
    },
    components: {
    }
}
</script>