<template>
    <div>
        <InputLabelDescription :label="label" :optional="optional" :description="description"/>
        <div class="d-flex text-body-2 align-center">
            <v-checkbox :data-cy="dataCy" v-bind="$props" v-on="$listeners" class="ma-0 pa-0" dense
                :disabled="!edit" hide-details
                label="">
            </v-checkbox>
            <div>{{ text }}</div>
        </div>
    </div>
</template>


<script>
import { VCheckbox } from 'vuetify/lib'
import InputLabelDescription from './InputLabelDescription.vue';

export default {
    name: "FormCheckbox",
    extends: VCheckbox,
    props: {
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        optional: {
            type: Boolean,
            default: false,
        },
        "data-cy": {
            type: String,
            default: "",
        },
        edit: {
            type: Boolean,
            default: true,
            required: false,
        },
        description: {
            type: String,
            required: false,
            default: "",
        },
        hideDetails: {
            type: [String, Boolean],
            default: "auto",
            required: false,
        },
    },
    components: { InputLabelDescription }
}
</script>