<template>
    <div>
        <v-row no-gutters @click="toggle" :style="{ cursor: !alwaysShow ? 'pointer' : 'initial' }" :align="alignHeader" :justify="justifyHeader">
            <slot name="header" v-bind="{ show }"/>
            <v-icon v-if="!alwaysShow" class="pl-2" :color="iconColor">{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-row>
        <div v-show="alwaysShow || show">
            <slot name="content"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExpandableSection",
    props: {
        iconColor: {
            type: String,
            default: "black",
        },
        alwaysShow: {
            type: Boolean,
            default: false,
        },
        alignHeader: {
            type: String,
            default: "center",
        },
        justifyHeader: {
            type: String,
            default: "start"
        },
        isOpenByDefault: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            show: this.isOpenByDefault,
        }
    },
    methods: {
        toggle() {
            this.show = !this.show
        }
    }
}
</script>