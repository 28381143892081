import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import api from './api'
import mixins from './mixins';

import VueMeta from 'vue-meta';

import posthog from 'posthog-js'

try {

  if (window.location.hostname === 'app.yeshid.com') {
    posthog.init('phc_XwKYhxR8vRhSy1KqOby9hgXIgkjJfevdrGcmf4rPVRe',{
      api_host: 'https://app.yeshid.com/api/v1/posthog',
      ui_host: 'https://app.posthog.com',
    })
  }

  router.afterEach(() => {
    // Sends a $pageview event to PostHog every time the route changes
    posthog.capture('$pageview');
  });
} catch (e) {
  console.log('error initializing posthog', e)
}

Vue.use(VueMeta);
Vue.mixin(mixins)

// allow the whole app to use axios globally, eg: "this.$http.get()"
Vue.prototype.$http = axios
Vue.prototype.$api = api

// intercept all responses and redirect to login if the response is 401
axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {

      // if the current route already has a redirect query param, don't try to redirect again
      // this happens when there are a lot of lin-flight api requests that return 401 all at
      // once, so not trapping this causes the redirect param to "pile up" a bunch of times
      if (router.currentRoute.query.redirect) {
        return Promise.reject(error)
      }

      // redirect to login and pass the current route as a query param so we can redirect back
      router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } })
    }
    return Promise.reject(error)
  }
)

// enables performance measuring in chrome
const isDev = process.env.NODE_ENV !== "production"
Vue.config.performance = isDev

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')