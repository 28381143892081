import Axios from "axios"
import { setupCache } from 'axios-cache-interceptor';

const axiosInstance = Axios.create();
const axios = setupCache(axiosInstance);

function buildFilter(text, type, items) {
    return {
        text,
        type,
        items,
        selected: [],
    }
}

function buildFilterItem(field, value, text, op = "=", data = {}, conditions = []) {
    return {
        field,
        value,
        text,
        op,
        data,
        conditions,
    }
}

function buildFilterItems(field, values, text, op = "=", data = {}, conditions = []) {
    return values.map(v => buildFilterItem(field, v, text, op, data, conditions))
}

function formatCondition(selected) {
    if (selected.conditions.length) {
        return selected.conditions.map(c => formatCondition(c))
    }

    const { field, value, op } = selected
    if (!field || value === undefined) {
        return ""
    }

    if (field.toLowerCase().includes("id")) {
        return `${field} ${op} ${value}`
    } else if (typeof value === "string") {
        return `${field} ${op} "${value}"`
    } else {
        return `${field} ${op} ${value}`
    }
}

function orConditions(conds) {
    if (conds && conds.length) {
        return conds.filter(c => !!c).join(" OR ")
    }

    return ""
}

function andConditions(conds) {
    if (conds && conds.length) {
        return conds.filter(c => !!c).join(" AND ")
    }

    return ""
}

function buildFilterParam(filters) {
    // when we need to just define the filters on the api call
    if (typeof filters === "string") {
        return filters
    }

    let conditions = []
    for (const f of filters) {
        const tempConds = f.selected.map(s => formatCondition(s)).flat()
        const hasNots = tempConds.some(s => s.includes("!="))

        if (hasNots) {
            // TODO: fix later temporary hack to get != working properly within filters applied through url params
            conditions.push(andConditions(tempConds))
        } else {
            conditions.push(orConditions(tempConds))
        }
    }

    const fil = andConditions(conditions)

    return `${fil}`
}

const basePath = "/api/v1"
const buildPath = (endpointPath) => `${basePath}/${endpointPath}`

const baseApiState = (initialData, additionalState = {}) => ({
    ...additionalState,
    data: initialData,
    loading: false,
    error: "",
    filters: [],
    // add pagination here in the future
})

function get(url, state = {}, dataKey = "", callbacks = {onSuccess: () => {}, onError: () => {}, onComplete: () => {}}, config = {}) {

    if (! config.cache) {
        config.cache = false
    }

    const axiosConfig = { ...config }    
    const filterParam = buildFilterParam(state.filters)

    if (filterParam) {
        axiosConfig.params = {
            ...axiosConfig.params,
            filter: filterParam,
        }
    }

    state.loading = true
    return axios.get(url, axiosConfig)
        .then(response => {
            if (dataKey) {
                state.data = response.data[dataKey]
            } else {
                state.data = response.data
            }
            callbacks.onSuccess && callbacks.onSuccess(response)
        })
        .catch(error => {
            console.log('error', error)
            state.error = error?.response?.data?.message
            callbacks.onError && callbacks.onError(error)
        })
        .finally(() => {
            state.loading = false
            callbacks.onComplete && callbacks.onComplete()
        })
}

function getUsers(state, callbacks, options = {}) {
    let url = buildPath("users")
    get(url, state, "users", callbacks, options)
}

function getUser(id, state, callbacks, options = {}) {
    let url = buildPath(`user/${id}`)
    get(url, state, "", callbacks, options)
}

function getUsersAccess(state, callbacks, options = {}) {
    let url = buildPath("users/access")
    get(url, state, "", callbacks, options)
}

function getApplications(state, callbacks, options = {}) {
    let url = buildPath("applications")
    return get(url, state, "applications", callbacks, options)
}

function getApplicationsCatalog(state, callbacks, options = {}) {
    let url = buildPath("applications/catalog")
    get(url, state, "applications", callbacks, options)
}

function getApplicationsIdentities(state, callbacks, options = {}) {
    let url = buildPath("applications/identities")
    get(url, state, "", callbacks, options)
}

function getUserApplications(id, state, callbacks, options = {}) {
    let url = buildPath(`applications/user/${id}`)
    get(url, state, "applications", callbacks, options)
}

function getApplication(id, state, callbacks, options = {}) {
    let url = buildPath(`application/${id}`)
    get(url, state, "application", callbacks, options)
}

function getMemberGroups(id, state, callbacks, options = {}) {
    let url = buildPath(`groups/member/${id}`)
    get(url, state, "groups", callbacks, options)
}

function getGroups(state, callbacks, options = {}) {
    let url = buildPath(`groups`)
    get(url, state, "groups", callbacks, options)
}

function getOrgUnits(state, callbacks, options = {}) {
    let url = buildPath(`orgunits/summary`)
    get(url, state, "orgUnitsList", callbacks, options)
}

function getDirectories(state, callbacks, options = {}) {
    let url = buildPath('directories')
    get(url, state, "directories", callbacks, options)
}

function getIntegrations(state, callbacks, options = {}) {
    let url = buildPath(`integrations`)
    get(url, state, "integrations", callbacks, options)
}

function getIntegration(id, state, callbacks, options = {}) {
    let url = buildPath(`integration/${id}`)
    get(url, state, "integration", callbacks, options)
}

function getCatalogIntegrations(state, callbacks, options = {}) {
    let url = buildPath(`integrations/catalog`)
    get(url, state, "integrations", callbacks, options)
}

function getUnusedCatalogIntegrations(state, callbacks, options = {}) {
    let url = buildPath(`integrations/catalog/unused`)
    get(url, state, "integrations", callbacks, options)
}

function getLabels(state, callbacks, options = {}) {
    let url = buildPath("labels")
    get(url, state, "labels", callbacks, options)
}

function getThirdPartyApplications(state, callbacks, options = {}) {
    let url = buildPath("applications/thirdparty")
    get(url, state, "applications", callbacks, options)
}

function getOauthGrants(state, callbacks, options = {}) {
    let url = buildPath("oauthgrants")
    get(url, state, "oauthGrantUsers", callbacks, options)
}

function getOauthGrantUsers(id, state, callbacks, options = {}) {
    let url = buildPath(`oauthgrants/${id}/users`)
    get(url, state, "oauthGrantUsers", callbacks, options)
}

function getOrgSettings(id, state, callbacks, options = {}) {
    let url = buildPath(`org/${id}/settings`)
    get(url, state, "", callbacks, options)
}

// function saveInviteLinkIntegration(id, data, callbacks) {
async function saveInviteLinkIntegration(inviteLink, integration = {}, catalogIntegration = {}, applicationId = "", callbacks) {
    if (!inviteLink && integration.id) {
        // delete
        axios.delete(`/api/v1/integration/${integration.id}`).then((response) => {
            console.log(response)
            callbacks.onSuccess && callbacks.onSuccess(response)
            // this.$root.$emit('toast', `Disconnected integration for ${provIntg.name}`, 'success')
        }).catch((err) => {
            console.log(err)
            callbacks.onError && callbacks.onError(err)
            // this.$root.$emit('toast', `Error disconnecting integration for ${provIntg.name}`)
        }).finally(() => {
            callbacks.onComplete && callbacks.onComplete()
            // this.initialInviteLink = this.inviteLink
            // this.edit = false
            // this.$emit("updated")
        })
    } else if (integration.id) {
        // update
        let url = `/api/v1/integration/${integration.id}`
        axios.put(url, {
            properties: [{ id: "provisioning.magic_link", value: inviteLink }]
        }).then((response) => {
            console.log(response)
            callbacks.onSuccess && callbacks.onSuccess(response)
        }).catch((err) => {
            console.log(err)
            callbacks.onError && callbacks.onError(err)
        }).finally(() => {
            callbacks.onComplete && callbacks.onComplete()
        })
    } else if (inviteLink) {
        // create
        let url = `/api/v1/integration`
        axios.post(url, {
            name: catalogIntegration.name,
            integrationId: catalogIntegration.id,
            orgApplicationId: applicationId,
            properties: [{ id: "provisioning.magic_link", value: inviteLink }]
        }).then((response) => {
            console.log(response)
            callbacks.onSuccess && callbacks.onSuccess(response)
        }).catch((err) => {
            console.log(err)
            callbacks.onError && callbacks.onError(err)
        }).finally(() => {
            callbacks.onComplete && callbacks.onComplete()
        })
    }
}

export default {
    state: {
        base: baseApiState,
    },
    get: {
        users: getUsers,
        user: getUser,
        usersAccess: getUsersAccess,
        applications: getApplications,
        application: getApplication,
        applicationsCatalog: getApplicationsCatalog,
        applicationsIdentities: getApplicationsIdentities,
        groups: getGroups,
        orgUnits: getOrgUnits,
        directories: getDirectories,
        memberGroups: getMemberGroups,
        userApplications: getUserApplications,
        integrations: getIntegrations,
        integration: getIntegration,
        catalogIntegrations: getCatalogIntegrations,
        unusedCatalogIntegrations: getUnusedCatalogIntegrations,
        labels: getLabels,
        thirdPartyApplications: getThirdPartyApplications,
        oauthGrants: getOauthGrants,
        oauthGrantUsers: getOauthGrantUsers,
        orgSettings: getOrgSettings,
    },
    post: {

    },
    delete: {

    },
    saveInviteLinkIntegration: saveInviteLinkIntegration,
    filters: {
        buildFilter,
        buildFilterItem,
        buildFilterItems,
        buildFilterParam,
    }
}