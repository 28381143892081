<template>
    <div>
        <v-layout class="pb-4 align-center">
            <FilterBar class="mr-2" :filters.sync="accessGrid.usersAccess.filters" :filterComponents="filterComponents" @applied="() => accessGrid.load()"/>
            <RefreshTable @click="() => accessGrid.load()"/>
            <ReorderTableHeader :tableHeaders.sync="accessGrid.headers" tableName="access"/>
            <TableMenu :tableHeaders="accessGrid.headers" :tableData="accessGrid.getUsersAccessData()" csvFilename="access" />
            <v-tooltip v-if="accessGrid.getNumApps()" top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="ml-2" color="primary" @click="finalizeChanges" height="44" elevation="0">{{ reviewTitle }}</v-btn>
                </template>
                Click here to review your changes
            </v-tooltip>
        </v-layout>
        <div :class="`grid-layout grid-gap-lg flex-nowrap ${floatingCart ? 'floating-cart-container' : ''}`">
            <v-data-table v-resize="calculateShadow" data-cy="access-table" style="width: 70%;" ref="accessTable" :headers.sync="accessGrid.headers" :items="accessGrid.getUsersAccessData()"
                :loading="accessGrid.loading" item-key="id"
                class="first-column-sticky" :class="pinnedShadow ? 'sticky-shadow' : ''"
                fixed-header height="calc(100vh - 380px)"
                :items-per-page="20">
                <template slot="no-data">
                    <div class="pa-4 text-secondary--text d-flex grid-layout-column align-center justify-center" style="height: calc(100vh - 380px)">
                        <v-img :src="require('@/assets/thinking-person.svg')" max-width="200" class="mb-4 grid-child-auto" contain/>
                        <div class="text-h5"><b>You don't have any applications yet.</b></div>
                        <div>Start by importing or adding applications!</div>
                        <div class="mt-4">
                            <v-btn v-if="hasImportFlag" class="mr-2" elevation="0" outlined text @click="importApplicationDialogToggle = true">Import application list</v-btn>
                            <v-btn color="primary" elevation="0" @click="addApplicationDialogToggle = true">Add Applications</v-btn>
                        </div>
                    </div>
                </template>
                <template v-for="(h, i) in accessGrid.headers" v-slot:[`header.${h.value}`]>
                    <AccessGridHeader @selectAll="() => accessGrid.selectAllFor(h.value)" @unselectAll="() => accessGrid.unselectAllFor(h.value)" @importFromCSV="importFromCSVFor" v-if="h.application" class="d-flex grow justify-center" :key="`app-${h.value}`" :application="h.application || {}"/>
                    <span v-else :key="`user-${i}`">{{ h.text }}</span>
                </template>
                <template #[`item.name`]="{ item }">
                    <UserCell :item="item" />
                </template>
                <template v-for="(col, i) of accessGrid.headers.slice(1)" #[`item.${col.value}`]="{ item }">
                    <AccessGridCheckbox :item="item" :col="col" :key="`${col.value}-${i}`" :on-change="handleCheckboxChange" :accessChangeTooltipText="accessChangeTooltipText" :accessGrid="accessGrid" />
                </template>
            </v-data-table>
            <AccessGridCart
                :open="finalize"
                :accessGrid="accessGrid"
                :floating="floatingCart"
                @close="closeCart"
                @apply="confirmFinalizeChanges"/>
        </div>
        <NavigateAwayWarning ref="navigateAway" :enabled="accessGrid.hasChanges"/>
        <BaseDialog :show="showImportFromCSV" :close="closeImportCSVDialog">
            <template slot="title">
                <div class="d-flex">
                    <span class="mr-2">Import</span>
                    <ApplicationNameCell :application="importFromCSVApp" :size="16"/>
                    <span class="ml-2">users in YeshID</span>
                </div>
            </template>
            <template slot="content">
                <div v-if="!importFromCSVResults" class="body-2">
                    <div>
                        <div class="mb-2">
                            In order to keep your access management grid up to date with the users of
                            <ApplicationIcon style="display: inline-block" :application="importFromCSVApp" :size="16" /> {{ importFromCSVApp.name }}, follow these steps:
                        </div>
                        <ol class="mb-2">
                            <li>Download a CSV of your <ApplicationIcon style="display: inline-block" :application="importFromCSVApp" :size="16" /> {{ importFromCSVApp.name }} users</li>
                            <li>In YeshID, click "Upload CSV" and select the downloaded file.</li>
                        </ol>
                        You will have a chance to review changes before saving.
                    </div>
                    <v-btn class="mt-2" @click="triggerFileInput" elevation="0" text outlined >
                        <span>Upload your CSV</span>
                    </v-btn>
                    <input type="file" ref="importCsvFileInput" style="display: none" @change="e => processImportCSV(e)" accept="text/csv" />
                </div>
                <div v-else class="body-2">
                    <b>Your CSV import is complete, time to review the changes!</b>
                    <div class="mt-2">
                        We found {{ importFromCSVResults.known.length }} users in your CSV file.
                    </div>
                    <div class="mt-2" v-if="importFromCSVResults.unknown.length">
                        <v-alert text type="warning">
                        <div class="d-flex mb-2">
                            <span class="mr-2">We also identified email addresses in</span>
                            <ApplicationNameCell :application="importFromCSVApp" :size="16"/>
                            <span class="ml-2">that aren't currently in your YeshID directory.</span>
                        </div>
                        <ul class="mb-2">
                            <li v-for="email in importFromCSVResults.unknown" :key="email">{{ email }}</li>
                        </ul>
                        Since these email addresses don't match existing users, we couldn't import them.
                        </v-alert>
                    </div>
                    <div class="mt-2">
                        We have staged these changes, which you can review and approve in the "Review your changes" dialog.
                    </div>
                </div>
            </template>
            <template slot="actions"><v-btn @click="closeImportCSVDialog">Close</v-btn></template>
            <template slot="error" v-if="importFromCSVError">
                The system couldn't identify a field that looks like email addresses. YeshID depends on an email address to match users between YeshID and {{ importFromCSVApp.name }}.
            </template>
        </BaseDialog>
        <UploadAccessCSV :toggle.sync="showCSVImportMapping" :application="importFromCSVApp"/>
        <ImportApplicationsDialog :toggle.sync="importApplicationDialogToggle" @completed="accessGrid.load()"/>
        <AddApplicationDialog :toggle.sync="addApplicationDialogToggle" @addApplicationComplete="accessGrid.load()"/>
    </div>
</template>

<script>
import Papa from 'papaparse';
import { mapGetters } from 'vuex'
import AccessGrid from "@/lib/access_grid/access_grid.js"
import AccessGridCart from "./AccessGridCart.vue"
import AccessGridHeader from "./AccessGridHeader.vue";
import BaseDialog from '@/components/dialog/BaseDialog.vue';
import UserCell from "./UserCell.vue";
import ApplicationNameCell from "@/components/table/ApplicationNameCell.vue";
import ApplicationIcon from '@/components/ApplicationIcon.vue';
import RefreshTable from "@/components/RefreshTable.vue";
import TableMenu from "@/components/TableMenu.vue";
import EnrollmentStatusFilter from "./EnrollmentStatusFilter.vue";
import NameFilter from "./NameFilter.vue";
import SecureStatusFilter from "./SecureStatusFilter.vue";
import ApplicationsFilter from "./ApplicationsFilter.vue";
import LifecycleStatusFilter from "./LifecycleStatusFilter.vue";
import UserTypeFilter from "./UserTypeFilter.vue";
import LastNameFilter from "./LastNameFilter.vue";
import FilterBar from "@/components/filters/FilterBar.vue";
import ReorderTableHeader from "@/components/ReorderTableHeader.vue";
import NavigateAwayWarning from "@/components/NavigateAwayWarning.vue";
import ImportApplicationsDialog from '@/views/Application/ImportApplicationsDialog.vue';
import AddApplicationDialog from '@/views/Application/AddApplicationDialog.vue';
import AccessGridCheckbox from './AccessGridCheckbox.vue';
import UploadAccessCSV from '../User/UploadAccessCSV.vue';

export default {
    props: {
        filters: {
            type: Array,
            default: Array,
        },
    },
    data() {
        return {
            accessGrid: new AccessGrid(),
            viewHeader: {
                icon: "mdi-lock",
                title: "Access",
                info: "Manage user-application access. Review permissions and launch workflows here.",
            },
            finalize: true,
            filterComponents: [
                ApplicationsFilter,
                EnrollmentStatusFilter,
                LastNameFilter,
                NameFilter,
                LifecycleStatusFilter,
                SecureStatusFilter,
                UserTypeFilter,
            ],
            filterTypes: [
            ],
            pinnedShadow: false,
            // mdAndDown tells us if the window width is less than or equal to the medium width breakpoint
            floatingCart: this.$vuetify.breakpoint.mdAndDown,
            showImportFromCSV: false,
            showCSVImportMapping: false,
            importFromCSVApp: {},
            importFromCSVError: "",
            importFromCSVResults: null,
            hasImportFlag: false,
            importApplicationDialogToggle: false,
            addApplicationDialogToggle: false,
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$refs?.navigateAway?.prepareToExit(to, from, next)
    },
    mounted() {
        this.hasImportFlag = this.checkFeatureFlag('application-importer')

        this.$root.$on('syncedWithGoogle', () => {
            this.accessGrid.load()
        });
    },
    beforeDestroy() {
        this.$root.$off('syncedWithGoogle')
    },
    computed: {
        ...mapGetters({
            checkFeatureFlag: "checkFeatureFlag",
        }),
        hasNewTasklist() {
            return this.checkFeatureFlag("orchestration-full")
        },
        reviewTitle() {
            return `Review (${this.accessGrid.getNumChanges()}) change${this.accessGrid.getNumChanges() != 1 ? 's' : ''}`
        },
    },
    methods: {
        handleCheckboxChange(e) {
            let data = e.target.dataset
            this.accessGrid.handleUpdate(data.userId, data.appId, e.target.checked)
        },
        calculateShadow() {
            const accessTable = this.$refs?.accessTable?.$el
            if (accessTable) {
                const wrapper = accessTable.getElementsByClassName("v-data-table__wrapper")[0]
                const table = wrapper.children[0]

                const wrapperRect = wrapper.getBoundingClientRect()
                const tableRect = table.getBoundingClientRect()

                const scrollbarWidth = wrapper.offsetWidth - wrapper.clientWidth

                this.pinnedShadow = (wrapperRect.width - scrollbarWidth) < tableRect.width
            }
        },
        accessChangeTooltipText(status) {
            switch (status) {
                case "ACTIVE":
                    return "Click to remove access"
                case "TO_BE_PROVISIONED":
                    return "To be provisioned"
                case "TO_BE_REMOVED":
                    return "To be removed"
                case "INVITED":
                    return "Invited"
                case "DEACTIVATED":
                case "":
                    return "Click to add access"
                default:
                    return "Unknown status: " + status
            }
        },
        finalizeChanges() {
            this.finalize = true
        },
        closeCart() {
            this.finalize = false
        },
        async confirmFinalizeChanges(createTasks) {
            const success = await this.accessGrid.save(createTasks)

            if (success) {
                this.$root.$emit("toast", "Successfully updated access", "success");
            } else {
                this.$root.$emit("toast", "There was an issue updating access", "error")
            }
        },
        importFromCSVFor(app) {
            this.importFromCSVApp = app
            if (this.checkFeatureFlag("new-csv-import")) {
                this.showCSVImportMapping = true
            } else {
                this.showImportFromCSV = true
            }
        },
        closeImportCSVDialog() {
            this.showImportFromCSV = false
            this.importFromCSVError = ""
            this.importFromCSVResults = null
        },
        triggerFileInput() {
            this.$refs.importCsvFileInput.click()
        },
        probeDataForEmailIndex(rows, startingRow, targetRegex) {
            // if we don't have any actual data rows, bail out
            if (rows.length <= startingRow) {
                return -1
            }

            // go through the first row marking down all candidate columns
            let current = startingRow
            let candidates = []
            for (let i = 0; i < rows[current].length; i++) {
                if (targetRegex.test(rows[current][i])) {
                    candidates.push(i)
                }
            }

            // if we have no candidates, bail out
            if (candidates.length == 0) {
                return -1
            }

            // go through a few more rows, keeping only candidates where the values match
            for (current = current + 1; current < startingRow + 5; current++) {
                if (current == rows.length) {
                    break
                } else if (rows[current].length == 1 && rows[current][0] === '') {
                    // skip empty lines, as well as the last line this parsing library seems to add on its own
                    continue
                }

                let newCandidates = []
                for (let i = 0; i < candidates.length; i++) {
                    let ind = candidates[i]
                    if (ind < rows[current].length) {
                        if (targetRegex.test(rows[current][ind])) {
                            newCandidates.push(ind)
                        }
                    }
                }

                candidates = newCandidates
            }

            // if we have no candidates left, bail out, otherwise return the first one
            if (candidates.length == 0) {
                return -1
            } else {
                return candidates[0]
            }
        },
        processImportCSV(e) {
            this.importFromCSVError = ""
            let csv = e.target.files[0]
            let that = this
            Papa.parse(csv, {
                complete: function(results) {
                    if (results.errors.length) {
                        console.log('csv parse errors', results.errors)
                    }

                    let data = results.data
                    let headerRow = false
                    if (data.length < 1) {
                        return
                    }

                    that.createTasks = false
                    let emailRegex = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+(\.\w{2,63})+$/

                    // try to figure out which array index to use as the email field and store that for the loop, assuming no headers
                    let emailIdx = that.probeDataForEmailIndex(data, 0, emailRegex)

                    // if none found, try to look through the headers for a clue
                    if (emailIdx < 0) {
                        if (data.length < 2) {
                            // we don't have enough rows for the first one to be a header row...
                            that.importFromCSVError = "Could not detect a field to use for the email addresses of your users"
                            return
                        }

                        // we don't have an email field... let's see if we can find a header and then test a value
                        for (let i = 0; i < data[0].length;i++) {
                            let field = data[0][i]
                            if (field.toLowerCase().includes('email')) {
                                // could be an email header, let's test that field in the first row
                                if (emailRegex.test(data[1][i])) {
                                    // looks like an email addy... we're good
                                    emailIdx = i
                                    headerRow = true
                                    break
                                }
                            }
                        }
                    }

                    // still no dice.  Ignore the headers and run our original heuristic starting on row 1 (skipping the header)
                    if (emailIdx < 0) {
                        emailIdx = that.probeDataForEmailIndex(data, 1, emailRegex)
                    }
 
                    if (emailIdx < 0) {
                        that.importFromCSVError = "Could not detect a field to use for the email addresses of your users"
                        return
                    }

                    // build a set of emails that were found in the csv so we can reference them easily when we loop through our users
                    let emails = {}
                    data.slice(headerRow ? 1 : 0).forEach(r => {
                        if (r.length <= emailIdx) {
                            // this skips over what is most likely a trailing empty line
                            return
                        }
                        let email = r[emailIdx].toLowerCase()
                        // don't populate our map w/ things that aren't emails
                        if (emailRegex.test(email)) {
                            emails[email] = false
                        }
                    })

                    let importresults = { known: [], unknown: []}
                    that.accessGrid.usersAccess.data.forEach(ua => {
                        let found = false
                        let lcEmail = ua.email.toLowerCase()
                        if (emails[lcEmail] !== undefined) {
                            found = true
                            emails[lcEmail] = true
                            importresults.known.push(lcEmail)
                        }
                        if (!that.accessGrid.accessChangeDisabled(ua[that.importFromCSVApp.id].status)) {
                            that.accessGrid.handleUpdate(ua.id, that.importFromCSVApp.id, found)
                        }
                    })

                    // discover the users that are left over in the csv (we could not match them to a yesh user)
                    Object.keys(emails).forEach(k => {
                        if (!emails[k]) {
                            importresults.unknown.push(k)
                        }
                    })

                    that.importFromCSVResults = importresults
                }
            })
        },
    },
    watch: {
        "$vuetify.breakpoint.mdAndDown": function(val) {
            this.floatingCart = val
        },
        "accessGrid.loading": function() {
            this.calculateShadow()
        },
    },
    components: {
        UserCell,
        AccessGridHeader,
        ApplicationNameCell,
        ApplicationIcon,
        RefreshTable,
        TableMenu,
        FilterBar,
        ReorderTableHeader,
        NavigateAwayWarning,
        BaseDialog,
        ImportApplicationsDialog,
        AddApplicationDialog,
        AccessGridCart,
        AccessGridCheckbox,
        UploadAccessCSV,
    },
}
</script>

<style lang="scss">
.first-column-sticky > .v-data-table__wrapper > table > tbody > tr {
    > td:first-child:hover, &:hover td:first-child {
        background-color: #eeeeee;
    }
    &.v-data-table__empty-wrapper > td:first-child:hover {
        background-color: white;
    }
}

.first-column-sticky > .v-data-table__wrapper > table > tbody > tr > td:first-child,
.first-column-sticky > .v-data-table__wrapper > table > thead > tr > th:first-child {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background-color: white;
    border-top-left-radius: 8px;
}

.sticky-shadow > .v-data-table__wrapper > table > tbody > tr > td:first-child,
.sticky-shadow > .v-data-table__wrapper > table > thead > tr > th:first-child {
    &:after {
        content: " ";
        height: 100%;
        position: absolute;
        top: 0;
        width: 15px;
        opacity: .5
    }

    &:after {
      box-shadow: 12px 0 15px -15px inset;
      right: 0;
    }
}

.first-column-sticky > .v-data-table__wrapper > table > thead > tr > th:first-child {
    top: 0;
    z-index: 5;
}

</style>./AccessGridCart.vue
