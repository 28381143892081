import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import SecuritySummary from '../views/Security/SecuritySummary.vue'
import SecurityVizView from '../views/Security/SecurityVizView.vue'
import SecurityTableView from '../views/Security/SecurityTableView.vue'
import AccessGrid from '../views/User/AccessGrid.vue'
import AccessLog from '../views/User/AccessLog.vue'


Vue.use(VueRouter)

const separator = {
  meta: { separator: true }
}
const root = {
  path: '/',
  name: 'root-start',
  redirect: '/overview',
}

const docs = {
  icon: 'mdi-text-box-outline',
  text: 'Docs',
  href: 'https://yeshid.notion.site/fcfa23c566a24526b26e01ba991a4630',
  target: '_blank',
  meta: {},
}

const overview = {
    path: '/overview',
    icon: 'mdi-home',
    text: 'Home',
    name: 'overview',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
    children: [{
      path: '/overview',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "overview" */ '../views/Overview/OverviewView.vue'),
    }, {
      path: '/overview/start',
      name: 'overview-start',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "getting-started" */ '../views/Overview/GettingStarted.vue')
    }, {
      path: '/overview/onboard',
      name: 'overview-onboard',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "onboard" */ '../views/Overview/OnboardEmployees.vue')
    }, {
      path: '/overview/offboard',
      name: 'overview-offboard',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "offboard" */ '../views/Overview/OffboardEmployees.vue')
    }]
  }
const tasks = {
    path: '/tasks',
    name: 'tasks',
    icon: 'mdi-check-all',
    text: 'Tasks',
    meta: { showNavigation: true, authRequired: true },
    component: () => import(/* webpackChunkName: "tasks" */ '../views/Tasks/TasksView.vue'),
    children:[{
      path: '/tasks/:id',
      name: 'task-run',
      meta: { showNavigation: true, authRequired: true },
      component: () => import(/* webpackChunkName: "tasks-id" */ '../views/Tasks/TasksView.vue'),
    }],
}

const security = {
  path: '/security', // this is a gross hack... the routes want to have a path but this is a menu grouping...
  icon: 'mdi-shield-account',
  text: 'Security',
  meta: { showNavigation: true, authRequired: true, adminRequired: true },
  component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
  children: [{
    path: '/security/shadow',
    icon: 'mdi-minus-circle-off',
    text: 'Shadow applications',
    name: 'security-summary',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "security" */ '../views/Security/SecurityView.vue'),
    children: [{
      path: '/security/shadow',
      meta: { showNavigation: true, authRequired: true, adminRequired: false },
      components: {
        securityComponent: SecuritySummary,
      }
    }, {
      path: '/security/shadow/scopes',
      alias: ['/security/shadow/scopes/:scope'],
      name: 'security-scopes',
      meta: { showNavigation: true, authRequired: true, adminRequired: false },
      components: {
        securityComponent: SecurityVizView,
      }
    }, {
      path: '/security/shadow/applications',
      name: 'security-apps',
      meta: { showNavigation: true, authRequired: true, adminRequired: false },
      components: {
        securityComponent: SecurityTableView,
      }
    }]
  }, {
    path: '/security/identities/google',
    icon: 'mdi-fingerprint',
    text: 'Identities',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
    children: [{
      path: '/security/identities/google',
      name: 'security-identities-google',
      meta: { showNavigation: true, authRequired: true, adminRequired: false },
      component: () => import(/* webpackChunkName: "security-identities-google" */ '../views/Security/Identities/SecurityIdentitiesView.vue'),
    }, {
      path: '/security/identities/applications',
      name: 'security-identities-applications',
      meta: { showNavigation: true, authRequired: true, adminRequired: false },
      component: () => import(/* webpackChunkName: "security-identities-applications" */ '../views/Security/Identities/SecurityIdentitiesAppsView.vue'),
    }]
  }]
}

const organization = {
  icon: '$organization',
  text: 'Organization',
  path: '/organization',
  meta: { showNavigation: true, authRequired: true, adminRequired: true },
  component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
  children: [{
      text: 'People',
      icon: 'mdi-account-multiple-outline',
      path: '/organization/users',
      name: 'organization-users',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "users" */ '../views/User/UsersView.vue')
    }, {
      path: '/organization/directories',
      alias: '/manage/integrations',
      icon: '$organization',
      name: 'organization-directories',
      text: 'Directories',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "integrations" */ '../views/Manage/Integrations/IntegrationsView.vue'),
      children: [{
        path: '/organization/directories/:id',
        name: 'organization-directories-primary',
        meta: { showNavigation: true, authRequired: true, adminRequired: true },
        component: () => import(/* webpackChunkName: "integrations" */ '../views/Manage/Integrations/IntegrationsView.vue'),
      }]
    },
  ]}

const access = {
  text: 'Access',
  path: '/access',
  meta: { showNavigation: true, authRequired: true, adminRequired: true },
  component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
  children:[{
    text: 'Applications',
    icon: 'mdi-apps',
    path: '/access/applications',
    alias: '/organization/applications',
    name: 'access',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
    children: [{
      path: '/access/applications',
      name: 'access-applications',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "applications" */ '../views/Application/ApplicationsView.vue'),
    }, {
      path: '/access/applications/:appId/accounts',
      alias: '/access/applications/:appId',
      name: 'access-applications-accounts',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "application-view" */ '../views/Application/ApplicationView.vue'),
    }, {
      path: '/access/applications/:appId/settings',
      name: 'access-applications-settings',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "application-view" */ '../views/Application/ApplicationView.vue'),
    }, {
      path: '/access/applications/:appId/saml',
      name: 'access-applications-saml',
      meta: { showNavigation: true, authRequired: true, adminRequired: true,
        hasAdditionalAccess: () => checkFeatureFlag("integrations-sso") },
      component: () => import(/* webpackChunkName: "application-view" */ '../views/Application/ApplicationView.vue'),
    }, {
      path: '/access/applications/:appId/oidc',
      name: 'access-applications-oidc',
      meta: { showNavigation: true, authRequired: true, adminRequired: true,
        hasAdditionalAccess: () => checkFeatureFlag("integrations-sso") },
      component: () => import(/* webpackChunkName: "application-view" */ '../views/Application/ApplicationView.vue'),
    }],
  }, {
    text: 'Access Grid',
    path: '/access/grid',
    icon: 'mdi-account-multiple-outline',
    name: 'access-grid',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "access" */ '../views/User/AccessView.vue'),
    props: {
      title: 'Access Grid',
      info: "Manage user-application access. Review permissions and launch workflows here.",
    },
    children: [{
      path: '/access/grid',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      components: {
        accessComponent: AccessGrid,
      },
   }]
  }, {
    text: 'Access Requests',
    path: '/access/requests',
    icon: 'mdi-lock-alert-outline',
    name: 'access-log',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "access" */ '../views/User/AccessView.vue'),
    props: {
      title: 'Access Requests',
      info: '',
    },
    children: [{
      path: '/access/requests',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      components: {
        accessComponent: AccessLog,
      },
    }]
  }],
}

const manage = {
  path: '/manage',
  icon: 'mdi-cog',
  text: 'Manage',
  meta: { showNavigation: true, authRequired: true, adminRequired: true },
  component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
  children: [
  {
    path: '/manage/administrators',
    name: 'manage-administrators',
    icon: 'mdi-shield-account',
    text: 'Administrators',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "administrators" */ '../views/Manage/Administrators/AdministratorsView.vue')
  },
  {
    path: '/tasks-templates',
    name: 'tasks-templates',
    icon: 'mdi-playlist-play',
    text: 'Tasks Templates',
    meta: { showNavigation: true, authRequired: true, adminRequired: true,
      hasAdditionalAccess: () => checkFeatureFlag("orchestration") },
    component: () => import(/* webpackChunkName: "workflows" */ '../views/Workflows/WorkflowTemplateView.vue'),
    children:[{
      path: '/tasks-templates/:id',
      name: 'tasks-templates-editor',
      meta: { showNavigation: true, authRequired: true, adminRequired: true },
      component: () => import(/* webpackChunkName: "workflows-id" */ '../views/Workflows/WorkflowTemplateView.vue'),
    }]
  },
  {
    path: '/manage/workflows',
    name: 'manage-workflows',
    icon: 'mdi-file-tree-outline',
    text: 'Workflows',
    meta: { showNavigation: true, authRequired: true, adminRequired: true,
      hasAdditionalAccess: () => !checkFeatureFlag("orchestration-full")
    },
    component: () => import(/* webpackChunkName: "workflows" */ '../views/Workflows/WorkflowView.vue'),
  },
  {
    path: '/manage/passkeys',
    name: 'manage-passkeys',
    icon: 'mdi-key',
    text: 'Passkeys',
    meta: { showNavigation: true, authRequired: true, adminRequired: true,
      hasAdditionalAccess: () => checkFeatureFlag('idp')
    },
    component: () => import(/* webpackChunkName: "passkeys" */ '../views/Passkey/PasskeysView.vue')
  },
  {
    path: '/manage/apikeys',
    name: 'manage-apikeys',
    icon: 'mdi-key',
    text: 'API Keys',
    meta: { showNavigation: true, authRequired: true, adminRequired: true,
      hasAdditionalAccess: () => checkFeatureFlag('api-access')
    },
    component: () => import(/* webpackChunkName: "apikeys" */ '../views/Manage/APIKeys/APIKeysView.vue')
  },  
  {
    path: '/events',
    name: 'events',
    icon: 'mdi-alert-box',
    text: 'Events',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "events" */ '../views/Event/EventsView.vue')
  },
  {
    path: '/manage/settings',
    name: 'manage-settings',
    icon: 'mdi-cog-outline',
    text: 'Settings',
    meta: { showNavigation: true, authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Manage/Settings/SettingsView.vue')
  }]
}

const myProfile = {
    path: '/my-profile',
    icon: 'mdi-account-key',
    text: 'My Passkeys',
    meta: { showNavigation: true, authRequired: true, adminRequired: false, ssoRequired: true,
      hasAdditionalAccess: () => checkFeatureFlag('idp')
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/EndUser/Profile/ProfileView.vue'),
  }
const myApps = {
  path: '/my-applications',
  icon: 'mdi-rhombus-split',
  text: 'My Apps',
  meta: { showNavigation: true, authRequired: true, adminRequired: false },
  props: { title: 'My Applications' },
  component: () => import(/* webpackChunkName: "user-applications-view" */ '../views/EndUser/Application/ApplicationsView.vue'),
  children: [{
    path: "/my-applications",
    name: 'my-applications',
    meta: { showNavigation: true, authRequired: true, adminRequired: false },
    components: {
      apps: () => import(/* webpackChunkName: "my-applications" */ '../views/EndUser/Application/MyApps.vue'),
    }
  }]
}
const requestedApps = {
  path: '/requested-applications',
  icon: 'mdi-clock-time-three-outline',
  text: 'Requested Apps',
  meta: { showNavigation: true, authRequired: true, adminRequired: false },
  props: { title: 'Requested Apps' },
  component: () => import(/* webpackChunkName: "user-applications-view" */ '../views/EndUser/Application/ApplicationsView.vue'),
  children: [{
    path: '/requested-applications',
    name: 'requested-applications',
    meta: { showNavigation: true, authRequired: true, adminRequired: false },
    components: {
      apps: () => import(/* webpackChunkName: "requested-applications" */ '../views/EndUser/Application/RequestedApps.vue'),
    }
  }]
}

const slackIntegration = {
    path: '/integrations/slack',
    name: 'slack-integration',
    meta: { showNavigation: true, authRequired: true },
    component: () => import(/* webpackChunkName: "login" */ '../views/Manage/Settings/SettingsView.vue')
  }

const backoffice = {
  path: '/backoffice',
  name: 'backoffice',
  icon: 'mdi-rocket',
  text: 'The BFG™',
  meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
  component: () => import(/* webpackChunkName: "nestedView" */ './NestedRouterView.vue'),
  children: [
    {
      path: '/backoffice/applications',
      name: 'backoffice-applications',
      text: 'Applications',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-applications" */ '../views/BackOffice/Application/BackOfficeApplicationsView.vue')
    },
    {
      path: '/backoffice/scopes',
      name: 'backoffice-scopes',
      text: 'Scopes',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-scopes" */ '../views/BackOffice/Scopes/ScopesView.vue')
    },
    {
      path: '/backoffice/integrations',
      name: 'backoffice-integrations',
      text: 'Integrations',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-integrations" */ '../views/BackOffice/Integration/BackOfficeIntegrationsView.vue')
    },
    {
      path: '/backoffice/unknown-applications',
      name: 'backoffice-unknown-applications',
      text: 'Unknown Applications',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-applications" */ '../views/BackOffice/UnknownApplication/BackOfficeUnknownApplicationsView.vue')
    },
    {
      path: '/backoffice/importer',
      name: 'backoffice-importer',
      text: 'Importer',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-importer" */ '../views/BackOffice/Application/BackOfficeImporter.vue')
    },
    {
      path: '/backoffice/design-library',
      name: 'design-library',
      text: 'Design Library',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-applications" */ '../views/BackOffice/DesignLibrary/ComponentsView.vue')
    },
    {
      path: '/backoffice/orgs',
      name: 'backoffice-orgs',
      text: 'Orgs',
      meta: { showNavigation: true, authRequired: true, backOfficeAdminRequired: true },
      component: () => import(/* webpackChunkName: "backoffice-orgs" */ '../views/BackOffice/Orgs/OrgsView.vue')
    },
  ]
}

const errorEnrollUser = {
  path: '/error/enroll-user',
  name: 'error-enroll-user',
  meta: { showNavigation: false, authRequired: false },
  component: () => import(/* webpackChunkName: "error-enroll-user" */ '../views/Error/EnrollUserError.vue')
}
const error403 = {
  path: '/error/403',
  name: 'error-403',
  meta: { showNavigation: false, authRequired: false },
  component: () => import(/* webpackChunkName: "error-403" */ '../views/Error/HttpForbidden.vue')
}
const error404 = {
  path: '/error/404',
  name: 'error-404',
  meta: { showNavigation: false, authRequired: false },
  component: () => import(/* webpackChunkName: "error-404" */ '../views/Error/HttpNotFound.vue')
}
const error500 = {
  path: '/error/500',
  name: 'error-500',
  meta: { showNavigation: false, authRequired: false },
  component: () => import(/* webpackChunkName: "error-500" */ '../views/Error/HttpInternalServerError.vue')
}
const login = {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
}
const logout = {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/LogoutView.vue')
}
const signup = {
  path: '/signup',
  name: 'signup',
  component: () => import(/* webpackChunkName: "signup" */ '../views/RegistrationFlow/RegisterAccountView.vue')
}
const enrollUser = {
    path: '/enroll-user',
    name: 'enroll-user',
    component: () => import(/* webpackChunkName: "enroll-user" */ '../views/RegistrationFlow/EnrollUser.vue')
  }
const enrollDevice = {
    path: '/enroll-device',
    name: 'enroll-device',
    component: () => import(/* webpackChunkName: "enroll-device" */ '../views/RegistrationFlow/EnrollDevice.vue')
  }
const onboarder = {
    path: '/onboarder',
    name: 'onboarder',
    meta: { authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "onboarder" */ '../views/Onboarder/OnboarderView.vue')
  }
const loginPasskey = {
    path: '/login-passkey',
    name: 'login-passkey',
    component: () => import(/* webpackChunkName: "login" */ '../views/PasskeyLoginView.vue')
  }
const adminLogin = {
    // put this here so that during the login transition, users won't just get thrown to a blank page
    path: '/admin-login',
    name: 'admin-login',
    redirect: '/login'
  }

const adminNavConfig = [
  overview,
  tasks,
  myApps,
  requestedApps,
  separator,
  organization,
  access,
  security,
  manage,
]

const backofficeNavConfig = [
  backoffice,
]

const userNavConfig = [
  myProfile,
]

export const navConfig = [
  // XXX
  // obviously this breaks if 'manage' is not the last thing in the list but rather than 
  // writing a function to prune things or enhance things since the menu has already changed
  // a few times it seemed better in the moment to do it this way as it's easy to get rid of
  ...[...adminNavConfig.slice(0, -1), {...manage, children: [...manage.children, docs]}],
  ...userNavConfig,
  separator,
  ...backofficeNavConfig,
]

export const routes = [
  root,

  ...userNavConfig,
  ...adminNavConfig,
  ...backofficeNavConfig,

  login,
  logout,
  signup,
  enrollUser,
  enrollDevice,
  onboarder,
  loginPasskey,
  adminLogin,
  slackIntegration,

  errorEnrollUser,
  error403,
  error404,
  error500,
].filter(x => x.path)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      const position = { behavior: 'smooth' }

      if (to.hash) {
        position.selector = to.hash
        if (document.querySelector(to.hash)) {
          return position
        }
      }

      return false
    }
  },
})

store.commit('initialiseStore');

const checkFeatureFlag = store.getters.checkFeatureFlag

router.beforeEach((to, from, next) => {

  let isAuthenticated = store.getters.isAuthenticated
  let isAdmin = store.getters.isAdmin
  let isBackOfficeAdmin = store.getters.isBackOfficeAdmin
  let authRequired = to.matched.some(record => record.meta.authRequired);
  let adminRequired = to.matched.some(record => record.meta.adminRequired);
  let backOfficeAdminRequired = to.matched.some(record => record.meta.backOfficeAdminRequired);
  let isOnboarded = store.getters.isOnboarded

  let needToCheckAccess = to.matched.some(record => !!record?.meta?.hasAdditionalAccess);
  let doesNotHaveAdditionalAccess = false
  if (needToCheckAccess) {
    // there may be multiple routes matched in to so we check them all to be safe
    doesNotHaveAdditionalAccess = to.matched.some(record => record?.meta?.hasAdditionalAccess && !record.meta.hasAdditionalAccess())
  }
  let noRoutesFound = to.matched.length === 0

  if (to.name === "logout") {
    // catch any edge cases of logging out from the onboarding logic below
    next()
  } else if (authRequired && !isAuthenticated) {
    // pass the current route to the login page so we can redirect back to it after login
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (adminRequired && !isAdmin) {
    // Send non admin users to the my-apps page
    next({ name: 'my-applications' })
  } else if (backOfficeAdminRequired && !isBackOfficeAdmin) {
    // User is not a back office admin, so send them to the login page
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (!isOnboarded && isAdmin && isAuthenticated && to.name !== "onboarder") {
    // Admin has not completed onboarding, redirect them there
    next({ name: 'onboarder'})
  // leaving this here in case we want to try and locking out the onboarder once people have completed it
  // } else if (isOnboarded && isAdmin && isAuthenticated && to.name === "onboarder") {
  //   next({ name: 'overview' })
  } else if (doesNotHaveAdditionalAccess) {
    // Send anyone who does not have the necessary additional access to the forbidden page
    next({ name: 'error-403' })
  } else if (noRoutesFound) {
    next({ name: 'error-404' })
  } else {
    next()
  }
})

export default router
